import { clone} from 'lodash';

const expander = (prop, propName) => {
  return (item) => {
    if (item[prop] && item[prop].length > 0) {
      return item[prop].map(ii => {
        const expanded = clone(item);
        delete expanded[prop];
        expanded[propName] = ii;
        expanded.expandedId = expanded.expandedId || `${expanded.id}-${ii.id}`;
        return expanded;
      });
    } else {
      return item;
    }
  }
}

export {expander}
